<template>
  <b2b-layout
    :heading="t('INBOX')"
    :page-title="t('BREADCRUMBS.DOCUMENT')"
    show-organisation-selector="true"
    @organisationSelected="setOrganisation"
    @organisationsError="setOrganisationsError"
  >
    <div slot="header">
      <telia-grid>
        <telia-text-spacing class="header-slot-content">
          <telia-p disable-hyphenation="true">
            {{ t("DESCRIPTION") }}
          </telia-p>
        </telia-text-spacing>
      </telia-grid>
    </div>

    <telia-grid class="search-grid">
      <telia-row>
        <telia-col width="3">
          <telia-skeleton class="search-skeleton" v-if="initalLoading" />
          <div class="search" v-if="!initalLoading">
            <telia-text-input @input="search">
              <div class="search-icon-wrapper">
                <telia-icon name="search"></telia-icon>
              </div>
            </telia-text-input>
          </div>
        </telia-col>
      </telia-row>
    </telia-grid>

    <telia-grid>
      <telia-row v-if="isLoading && !generalError">
        <telia-col width="12">
          <div class="spinner-wrapper">
            <b2x-spinner size="small" t-id="b2x-spinner"></b2x-spinner>
          </div>
        </telia-col>
      </telia-row>

      <telia-row v-if="!isLoading && generalError">
        <telia-col width="6">
          <telia-notification
            t-id="error-notification"
            variant="inline"
            status="warning"
            heading-tag="h3"
            html-aria-live="polite"
            html-role="alert"
          >
            <span slot="heading">
              <telia-p>{{ t("ERRORS.USER_INFO") }}</telia-p>
            </span>
          </telia-notification>
        </telia-col>
      </telia-row>

      <template v-else-if="!isLoading && !generalError">
        <telia-row>
          <telia-col width="12">
            <div style="padding-bottom: 2rem">
              <telia-p>
                {{
                  t("SHOWING_LENGTH", {
                    length1: correspondenceForCurrentPage.length,
                    length2: correspondence.length,
                  })
                }}
              </telia-p>
            </div>
          </telia-col>
        </telia-row>

        <telia-row>
          <telia-col width="12">
            <b2x-table-base>
              <thead>
                <tr>
                  <th>{{ t("TABLE.TYPE") }}</th>
                  <th>{{ t("TABLE.DATE") }}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-if="correspondenceForCurrentPage.length === 0"
                  t-id="empty-correspondence-row"
                >
                  <td colspan="8">
                    <telia-p class="table-information-container">
                      {{ t("TABLE.NO_DOCUMENTS") }}
                    </telia-p>
                  </td>
                </tr>

                <tr
                  v-for="(cor, index) in correspondenceForCurrentPage"
                  :key="cor.correspondenceId"
                  class="correspondence-row"
                >
                  <td
                    class="table-row-column table-row-column__type-col"
                    :title="cor.correspondenceType"
                  >
                    {{ t(`TYPE.${cor.correspondenceType}`) }}
                  </td>
                  <td class="table-row-column table-row-column__date-col">
                    {{ cor.correspondenceDate }}
                  </td>
                  <td class="download-button-col table-row-column">
                    <telia-button
                      :t-id="`get-correspondence-button-${index}`"
                      type="button"
                      variant="secondary"
                      @click="getCorrespondenceFile(cor.correspondenceId)"
                    >
                      {{ t("DOWNLOAD_BUTTON") }}
                    </telia-button>
                  </td>
                </tr>
              </tbody>
            </b2x-table-base>

            <b2x-paginator
              v-show="correspondence.length > defaultPageSize"
              :list-length="correspondence.length"
              :page-sizes="JSON.stringify(pageSizes)"
              :default-page-size="defaultPageSize"
              @paginationChange="handlePagination($event.detail.page, $event.detail.pageSize)"
            />
          </telia-col>
        </telia-row>
      </template>
    </telia-grid>
  </b2b-layout>
</template>

<script>
import { corpCustomerCorrespondenceService } from "@telia/b2b-rest-client";
import { getScopeId } from "@telia/b2b-customer-scope";
import { translateSetup, translateMixin } from "./locale";
import * as analytics from "@telia/b2b-web-analytics-wrapper";

export default {
  name: "Documents",
  mixins: [translateMixin],
  components: {},
  data() {
    return {
      selectedTscid: null,
      scopeId: null,
      organizations: [],
      orignalCorrespondence: [],
      correspondence: [],
      isLoading: true,
      generalError: false,
      initalLoading: true,
      defaultPageSize: 5,
      pageSizes: [5, 10],
      pageSize: 5,
      page: 1,
    };
  },
  computed: {
    correspondenceForCurrentPage() {
      return this.correspondence?.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
  },
  async created() {
    await translateSetup(["mybusiness"]);
  },
  async mounted() {
    await this.setup();
  },
  methods: {
    async setup() {
      try {
        this.scopeId = await getScopeId();
        this.initalLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.generalError = true;
        return;
      } finally {
        this.isLoading = false;
      }
    },
    async setOrganisation(event) {
      const tscid = event.detail;
      if (tscid === this.selectedTscid) return;
      this.isLoading = true;
      this.selectedTscid = tscid;
      await this.getDocuments(this.selectedTscid);
      this.isLoading = false;
    },
    setOrganisationsError() {
      this.generalError = true;
    },
    async getDocuments(tscid) {
      try {
        const resp = await corpCustomerCorrespondenceService.CorpCustomerCorrespondenceControllerService.getCorrespondenceUsingGet(
          this.scopeId,
          tscid
        );
        this.orignalCorrespondence = [...resp.correspondence];
        this.correspondence = [...resp.correspondence];
      } catch (error) {
        this.isLoading = false;
        this.generalError = true;
      }
    },
    async getCorrespondenceFile(correspondenceId) {
      try {
        const resp = await corpCustomerCorrespondenceService.CorpCustomerCorrespondenceControllerService.getCorrespondenceFileUsingGet(
          correspondenceId,
          this.scopeId,
          this.selectedTscid
        );

        analytics.trackEvent("b2b-documents", analytics.action.CLICK, "Ladda ner/Visa");

        /* Filter through the response object to get the type, name and base64 string for the file */
        const linkSource = `data:${resp.mediaType.type}/${resp.mediaType.subtype};base64,${resp.entity}`;
        let filetype = resp.metadata["Content-Disposition"][0];
        let link = document.createElement("a");
        link.href = linkSource;
        link.download = filetype.split("=").pop();
        link.click();
      } catch (error) {
        this.isLoading = false;
        this.generalError = true;
      }
    },
    search(event) {
      const input = event.target.value.toLowerCase();
      if (input.length < 2) {
        if (input.length === 0) {
          this.correspondence = this.orignalCorrespondence;
        }
        return;
      }

      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.correspondence = this.orignalCorrespondence.filter((c) =>
          this.t(`TYPE.${c.correspondenceType}`).toLowerCase().includes(input)
        );
      }, 300);
    },
    handlePagination(page, pageSize) {
      this.page = page;
      this.pageSize = pageSize;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.search-grid {
  padding-bottom: $telia-spacing-20;
  padding-top: $telia-spacing-20;

  telia-row {
    display: flex;
    align-items: center;
  }

  .search {
    .search-icon-wrapper {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      pointer-events: none;
      color: $telia-gray-500;
    }

    telia-text-input {
      position: relative;
    }
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  padding: $telia-spacing-20;
}

.correspondence-row {
  &:nth-child(even) {
    background-color: $telia-gray-50;
  }
}

.search-skeleton {
  height: 5rem;
  width: 31rem;
}

.table-row-column {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &__type-col {
    width: 60%;
  }
  &__date-col {
    width: 20%;
  }
}

.download-button-col {
  width: 20%;
  text-align: end;
}
</style>
